import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function PharmacyAssistantHealthGuard({ children }) {
  const account = useSelector(state => state.account);

  //   console.log('account.user.roles', account.user.roles);

  if (!account.user) {
    return <Redirect to="/login" />;
  }

  if (account.user.roles) {
    if (account.user.roles[0].name === 'admin') {
      return <Redirect to="/admin" />;
    }

    if (account.user.roles[0].name === 'doctor') {
      return <Redirect to="/doctor" />;
    }

    if (account.user.roles[0].name === 'patient') {
      return <Redirect to="/patient" />;
    }

    if (account.user.roles[0].name === 'pharmacy-admin') {
      return <Redirect to="/pharmacy-admin" />;
    }

    if (account.user.roles[0].name === 'pharmacist') {
      return <Redirect to="/pharmacist" />;
    }

    if (account.user.roles[0].name === 'secretary') {
      return <Redirect to="/secretary" />;
    }

    if (account.user.roles[0].name === 'laboratory') {
      return <Redirect to="/laboratory" />;
    }

    if (account.user.roles[0].name === 'imaging') {
      return <Redirect to="/imaging" />;
    }
  }

  return children;
}

PharmacyAssistantHealthGuard.propTypes = {
  children: PropTypes.any
};

export default PharmacyAssistantHealthGuard;
