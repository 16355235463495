// eslint-disable no-use-before-define
// eslint-disable react/prop-types
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import {
  // AlignLeft,
  // Users,
  // UserCheck,
  // Folder,
  // Search,
  // MapPin,
  // FileText,
  // UserPlus,
  File,
  Home,
  Activity,
  Calendar,
  Users,
  DollarSign,
  Monitor,
  FileText,
  User,
  PieChart,
  Share2,
  ArrowRight,
  Settings,
  Truck,
  Clipboard,
  Archive,
  FilePlus,
  Download,
  Percent
  // MessageCircle
  // PieChart
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
// import CropFreeIcon from '@material-ui/icons/CropFree';
// import HealingIcon from '@material-ui/icons/Healing';

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, item, depth = 0 }) {
  const key = item.title + depth;
  acc.push(
    <React.Fragment key={key}>
      {/* {console.log("item.owner", item.owner)}
      {console.log("item.currentrole", item.currentrole)} */}
      <NavItem
        // style={{ display: item.currentrole === 'admin' ? "block" : item.owner === item.currentrole ? "block" : item.owner === 'all' ? "block" : "none" }}
        style={{
          display:
            item.currentrole === 'admin'
              ? 'block'
              : item.owner === item.currentrole
              ? // item.currentrole !== 'user-inhouse' ?
                'block'
              : // : "none"
              item.owner === 'personnel'
              ? item.currentrole === 'registrar'
                ? 'block'
                : item.currentrole === 'registrar-reviewer'
                ? 'block'
                : item.currentrole === 'treasurer'
                ? 'block'
                : 'none'
              : item.owner === 'officer'
              ? item.currentrole !== 'applicant'
                ? 'block'
                : 'none'
              : item.owner === 'all'
              ? 'block'
              : 'none'
        }}
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={item.title}
        enabled={item.enabled}
      />
    </React.Fragment>
  );

  return acc;
}

const imageLocation = process.env.REACT_APP_IMAGE;

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector(state => state.account);
  const navConfig = [
    //admin
    {
      subheader: '',
      owner: 'admin',
      items: [
        {
          title: 'Clinics',
          icon: Home,
          href: '/admin/clinic',
          enabled: true
        },
        {
          title: 'Pharmacy',
          icon: Home,
          href: '/admin/pharmacy',
          enabled: true
        },
        {
          title: 'Doctors',
          icon: Activity,
          href: '/admin/doctor',
          enabled: true
        }
      ]
    },

    //doctor
    {
      subheader: '',
      owner: 'doctor',
      items: [
        {
          title: 'Appointment',
          icon: Calendar,
          href: '/doctor/appointment',
          enabled: true
        },
        {
          title: 'Patient List',
          icon: Users,
          href: '/doctor/patient',
          enabled: true
        },
        {
          title: 'Services',
          icon: Monitor,
          href: '/doctor/service',
          enabled: true
        },
        {
          title: 'Reports',
          icon: FileText,
          href: '/doctor/report',
          enabled: true
        }
      ]
    },

    //secretary
    {
      subheader: '',
      owner: 'secretary',
      items: [
        {
          title: 'Appointment',
          icon: Calendar,
          href: '/secretary/appointment',
          enabled: true
        },
        {
          title: 'Patient List',
          icon: Users,
          href: '/secretary/patient',
          enabled: true
        },
        {
          title: 'Billing',
          icon: DollarSign,
          href: '/secretary/billing',
          enabled: true
        },
        {
          title: 'Reports',
          icon: FileText,
          href: '/secretary/report',
          enabled: true
        },
        {
          title: 'Follow Up',
          icon: User,
          href: '/secretary/follow-up',
          enabled: true
        }
      ]
    },

    //patient
    {
      subheader: '',
      owner: 'patient',
      items: [
        {
          title: 'Profile',
          icon: User,
          href: '/patient/profile',
          enabled: true
        },
        {
          title: 'Vitals',
          icon: ArrowRight,
          href: '/patient/vitals',
          enabled: true
        },
        {
          title: 'Allergies',
          icon: ArrowRight,
          href: '/patient/allergies',
          enabled: true
        },
        {
          title: 'Diagnosis',
          icon: ArrowRight,
          href: '/patient/diagnosis',
          enabled: true
        },
        {
          title: 'Medication',
          icon: ArrowRight,
          href: '/patient/medication',
          enabled: true
        },
        {
          title: 'Diet',
          icon: ArrowRight,
          href: '/patient/diet',
          enabled: true
        },
        {
          title: 'Family History',
          icon: ArrowRight,
          href: '/patient/family-history',
          enabled: true
        },
        {
          title: 'Laboratory',
          icon: ArrowRight,
          href: '/patient/laboratory',
          enabled: true
        },
        {
          title: 'Imaging',
          icon: ArrowRight,
          href: '/patient/imaging',
          enabled: true
        },
        // {
        //   title: 'History',
        //   icon: ArrowRight,
        //   href: '/patient/history',
        //   enabled: true,
        // },
        {
          title: 'Health Graph',
          icon: PieChart,
          href: '/patient/health-graph',
          enabled: true
        },
        // {
        //   title: 'Pain',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/pain',
        //   enabled: true
        // },
        // {
        //   title: 'Temperature',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/temperature',
        //   enabled: true
        // },
        // {
        //   title: 'Blood Pressure',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/blood-pressure',
        //   enabled: true
        // },
        // {
        //   title: 'Pulse',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/pulse',
        //   enabled: true
        // },
        // {
        //   title: 'Respiratory',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/respiratory',
        //   enabled: true
        // },
        // {
        //   title: 'Weight',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/weight',
        //   enabled: true
        // },
        // {
        //   title: 'Glucose',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/glucose',
        //   enabled: true
        // },
        // {
        //   title: 'Cholesterol',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/cholesterol',
        //   enabled: true
        // },
        // {
        //   title: 'Uric Acid',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/uric-acid',
        //   enabled: true
        // },
        // {
        //   title: 'Calcium',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/calcium',
        //   enabled: true
        // },
        // {
        //   title: 'Chloride',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/chloride',
        //   enabled: true
        // },
        // {
        //   title: 'Creatinine',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/creatinine',
        //   enabled: true
        // },
        // {
        //   title: 'HDL',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/hdl',
        //   enabled: true
        // },
        // {
        //   title: 'LDL',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/ldl',
        //   enabled: true
        // },
        // {
        //   title: 'Lithium',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/lithium',
        //   enabled: true
        // },
        // {
        //   title: 'Magnesium',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/magnesium',
        //   enabled: true
        // },
        // {
        //   title: 'Potassium',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/potassium',
        //   enabled: true
        // },
        // {
        //   title: 'Protein',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/protein',
        //   enabled: true
        // },
        // {
        //   title: 'Sodium',
        //   icon: ArrowRight,
        //   href: '/patient/health-graph/sodium',
        //   enabled: true
        // },
        {
          title: 'Clinic List',
          icon: Home,
          href: '/patient/clinic',
          enabled: true
        },
        {
          title: 'Permissions',
          icon: Share2,
          href: '/patient/permission',
          enabled: true
        }
      ]
    },

    //pharmacy-assistant
    {
      subheader: '',
      owner: 'pharmacy-assistant',
      items: [
        {
          title: 'Products',
          icon: Calendar,
          href: '/pharmacy-assistant/products',
          enabled: true
        },
        {
          title: 'Over the Counter',
          icon: Archive,
          href: '/pharmacy-assistant/over-the-counter',
          enabled: true
        }
        // {
        //   title: 'Configs',
        //   icon: Settings,
        //   href: '/pharmacy-assistant/configs',
        //   enabled: true
        // },
        // {
        //   title: 'Delivery',
        //   icon: Truck,
        //   href: '/pharmacy-assistant/delivery',
        //   enabled: true
        // },
        // {
        //   title: 'Report',
        //   icon: Clipboard,
        //   href: '/pharmacy-assistant/report',
        //   enabled: true
        // }
      ]
    },

    //pharmacist
    {
      subheader: '',
      owner: 'pharmacist',
      items: [
        {
          title: 'Products',
          icon: Calendar,
          href: '/pharmacist/products',
          enabled: true
        },
        {
          title: 'Over the Counter',
          icon: Archive,
          href: '/pharmacist/over-the-counter',
          enabled: true
        },
        {
          title: 'Delivery',
          icon: Truck,
          href: '/pharmacist/delivery',
          enabled: true
        },
        {
          title: 'Report',
          icon: Clipboard,
          href: '/pharmacist/report',
          enabled: true
        }
      ]
    },

    //pharmacy-admin
    {
      subheader: '',
      owner: 'pharmacy-admin',
      items: [
        {
          title: 'Products',
          icon: Calendar,
          href: '/pharmacy-admin/products',
          enabled: true
        },
        {
          title: 'Inventory Report',
          icon: File,
          href: '/pharmacy-admin/inventory-report',
          enabled: true
        },
        {
          title: 'Configs',
          icon: Settings,
          href: '/pharmacy-admin/configs',
          enabled: true
        },
        {
          title: 'Delivery',
          icon: Truck,
          href: '/pharmacy-admin/delivery',
          enabled: true
        },
        {
          title: 'Report',
          icon: Clipboard,
          href: '/pharmacy-admin/report',
          enabled: true
        },
        {
          title: 'Sales Discount',
          icon: Percent,
          href: '/pharmacy-admin/sales-discount',
          enabled: true
        },
        {
          title: 'COGS',
          icon: FileText,
          href: '/pharmacy-admin/cogs',
          enabled: true
        },
        {
          title: 'Gross Profit',
          icon: FilePlus,
          href: '/pharmacy-admin/net-sales',
          enabled: true
        },
        {
          title: 'Return',
          icon: Download,
          href: '/pharmacy-admin/return-product',
          enabled: true
        },
        {
          title: 'Void Transaction',
          icon: Clipboard,
          href: '/pharmacy-admin/void-transactions',
          enabled: true
        }
        // {
        //   title: 'Chat GPT',
        //   icon: MessageCircle,
        //   href: '/pharmacy-admin/chat-gpt',
        //   enabled: true
        // }
      ]
    },

    //laboratory
    {
      subheader: '',
      owner: 'laboratory',
      items: [
        {
          title: 'Patients',
          icon: Users,
          href: '/laboratory/patients',
          enabled: true
        },
        {
          title: 'Test',
          icon: Monitor,
          href: '/laboratory/test',
          enabled: true
        },
        {
          title: 'Report',
          icon: FileText,
          href: '/laboratory/report',
          enabled: true
        }
      ]
    },

    //imaging
    {
      subheader: '',
      owner: 'imaging',
      items: [
        {
          title: 'Patients',
          icon: Users,
          href: '/imaging/patients',
          enabled: true
        },
        {
          title: 'Test',
          icon: Monitor,
          href: '/imaging/test',
          enabled: true
        },
        {
          title: 'Report',
          icon: FileText,
          href: '/imaging/report',
          enabled: true
        }
      ]
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink
              to={
                user.roles[0].name.trim() === 'admin'
                  ? '/admin/account'
                  : user.roles[0].name.trim() === 'doctor'
                  ? '/doctor/account'
                  : user.roles[0].name.trim() === 'secretary'
                  ? '/secretary/account'
                  : user.roles[0].name.trim() === 'laboratory'
                  ? '/laboratory/account'
                  : user.roles[0].name.trim() === 'imaging'
                  ? '/imaging/account'
                  : user.roles[0].name.trim() === 'pharmacy-admin'
                  ? '/pharmacy-admin/account'
                  : user.roles[0].name.trim() === 'pharmacy-assistant'
                  ? '/pharmacy-assistant/account'
                  : user.roles[0].name.trim() === 'pharmacist'
                  ? '/pharmacist/account'
                  : '/patient/account'
              }
            >
              <Avatar
                alt={user.firstname}
                className={classes.avatar}
                src={`${imageLocation}avatar/${user.avatar}`}
              />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to={
                user.roles[0].name.trim() === 'admin'
                  ? '/admin/account'
                  : user.roles[0].name.trim() === 'doctor'
                  ? '/doctor/account'
                  : user.roles[0].name.trim() === 'secretary'
                  ? '/secretary/account'
                  : user.roles[0].name.trim() === 'laboratory'
                  ? '/laboratory/account'
                  : user.roles[0].name.trim() === 'imaging'
                  ? '/imaging/account'
                  : user.roles[0].name.trim() === 'pharmacy-admin'
                  ? '/pharmacy-admin/account'
                  : user.roles[0].name.trim() === 'pharmacy-assistant'
                  ? '/pharmacy-assistant/account'
                  : user.roles[0].name.trim() === 'pharmacist'
                  ? '/pharmacist/account'
                  : '/patient/account'
              }
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstname} ${user.lastname}`}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig
            // .filter((x) => (user.roles[0].name.trim() === 'admin' ? user.roles[0].name.trim() : user.roles[0].name.trim() === 'doctor' ? user.roles[0].name.trim() : "user") === x.owner)
            .filter(x => user.roles[0].name.trim() === x.owner)
            .map((config, index) => (
              <List
                key={index}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {config.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: config.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
        </Box>
        <Divider />
        <Hidden xsUp xsDown>
          <Box p={1}>
            <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
              <Typography variant="h6" color="textSecondary" align="center">
                To register your property? Click{' '}
              </Typography>

              <Box mt={1}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to="/app/property/register"
                  fullWidth
                >
                  {' '}
                  Register{' '}
                </Button>
              </Box>
            </Box>
          </Box>
        </Hidden>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
